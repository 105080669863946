import Footer from "@/page/login/1.0.0/components/footer/index.vue";
import FooterIcon from "@/page/help-center/components/footerIcon/index.vue";
import Header from "@/page/supplier/component/header/1.0.3/index.vue";

// 底部图标
import get_operation_guide_title_two from "@/lib/data-service/yinzhilv-temporary-storage-system/get_operation_guide_title_2";
const _ = require(`underscore`);

export default {
  data() {
    return {
      // 底部优势图片
      advantageDatas: [],
      //模块切换的开关
      dataOff: null,
      //right模块数据
      RightData:[],
      //left目录数据
      groupName:[],
    };
  },
  components: { Footer, Header,FooterIcon },
  created() {},
  mounted() {
    //根据url的id信息，设置默认内容
    this.dataOff = this.$route.query.id;

    //访问数据接口
    get_operation_guide_title_two()
      .then((data) => {
        const list = _.chain(data.data.allYinzhilvHelpCenterLists).groupBy(function (m) {
          return m.menuLevelFirstName;
        }).map(function (property_value, property_name) {
          const list = property_value;
          const menuLevelFirstName = property_name;
          const first = list[0];
          const menuLevelFirstIndex = first.menuLevelFirstIndex;

          return {
            menuLevelFirstName,
            menuLevelFirstIndex,
            list,
          };
        }).sortBy(function (m) {
          return m.menuLevelFirstIndex;
        }).value();

        list.forEach((item) => {
          this.groupName.push(item);
          item.list.forEach((data) => {
            this.RightData.push(data);
          });
        });

        //一级目录进行排序
        this.groupName = this.goSortdata(this.groupName,'menuLevelFirstIndex');
        //二级目录进行排序
        this.groupName.forEach((item) => {
          item.list = this.goSortdata(item.list,'menuLevelSecondIndex');
        });
      })
      .catch((err) => {
        console.log(`请求失败${err}`);
      });
  },
  activated() {},
  deactivated() {},
  destroyed() {},
  watch: {},
  computed: {},
  filters: {},
  methods: {
    //left目录单击事件
    click_color_blue (num) {
      this.dataOff = num;
      //改变url中的id
      console.log(this.$router);
      this.$router.push({
        query: {
          id: num,
        },
      });
    },
    goSortdata(data,basisName) {
      let dataTest = data.sort((a,b) => {
        if (eval(`a.${basisName}`) < eval(`b.${basisName}`)) {
          return -1;
        } else if (eval(`a.${basisName}`) < eval(`b.${basisName}`)) {
          return 0;
        } else  {
          return 1;
        }
      });
      return dataTest;
    },
  }
};
