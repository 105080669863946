import web_common_TourismMall_bottomTagList from "@/lib/data-service/default/web_common_TourismMall_bottomTagList";
export default {
  data() {
    return {
      // 底部优势图片
      advantageDatas: [],
    }
  },
  created() {},
  watch: {},
  mounted(){
    this.req_bottom_image();
  },
  methods: {
    // 底部图标
    req_bottom_image() {
      web_common_TourismMall_bottomTagList()
          .then((result) => {
            this.advantageDatas = result.bottomTagList;
          })
          .catch((err) => {});
    }
  },
};
